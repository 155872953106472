html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section  {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.row {
	margin: 0;
}

/* ------------------------------------------------------------------ */

#root{
	height:100%;
	background-color:#f5f5f8;
}

.text-bold {
	font-weight: 600;
}

.logo-cortado {
	width: 100%;
    max-width: 220px;
}

.logo {
	
}

body{
	height:100vh;
}

.body-login {
	background-color: #fff;
	height: 100%;
}

.body-login button{
	width: 100%;
	margin-top: 25px;
	height: 45px;
	padding-top: 14px;
}

.body-home {
	background-color: #F5F5F8;
}

.body-products {
	background-color: #F5F5F8;
}

body p, body input, body select {
	font-family: 'Raleway', sans-serif;
}

.header {
	background-color: #2AD2C9;
}

.header img {
}

.logo-container{
	text-align: center;
	display: flex;
  	justify-content: center;
  	align-items: center;
	flex-direction: column;
	min-height: 250px;
}

.body-products .header-int {
	padding-top: 20px;
}

.body {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	background-color: #fff;
	margin-top: -20px
}

.body-content {
	padding-top: 30px;
	padding-right: 50px;
	padding-left: 50px;
	padding-bottom: 50px;
}

.body-content-int {
	padding-top: 30px;
	padding-right: 20px;
	padding-left: 20px;
	padding-bottom: 50px;	
}

.title {
	font-family: 'Raleway', sans-serif;
	font-weight: 700;
	font-size: 18px;
}

.title-address{
	padding-bottom:25px;
}
.label {
	color: #868686;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	font-size: 15px;
}

.label-text {
	position: absolute;
	margin-top: 5px;
    margin-left: 7px;
}

.label-subscription {
	color: #868686;
	font-family: 'Raleway', sans-serif;
	font-size: 13px;
    padding-left: 4px;
}
input[type=checkbox]{
	cursor:pointer;
}
input[type=text]{
	color:black;
}

input[type=checkbox] {
	accent-color:#0A9E95
	;
}

.form-text {
	border:none;
	border-bottom: 1px solid #C9C9C9;
	padding: 5px 10px;
	outline: none;
	font-size: 17px;
	width: 100%;
}

[placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease; 
    text-indent: -100%;
    opacity: 1;
}

.btn-primary {
	background-color: #0A9E95;
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	width: 95%;
	padding: 20px;
	border-width: 0;
}

.btn-primary:hover {
	background-color: #0A9E95;
}

.btn-primary:focus {
	background-color: #0A9E95 !important;
}

.form-group {
	margin-top: 50px;
}

.form-group-actions {
	margin-top: 100px;
}

.ecard, .ecard-with-avatar {
	background-color: #fff;
}

a{
	color:#212529;
}

a:link { text-decoration: none; color:#212529;}


a:visited { text-decoration: none; color:#212529;}


a:hover { text-decoration: none; color:#212529;}


a:active { text-decoration: none; color:#212529;}

.avatar {
	border-radius: 76px;
	background-color: #868686;
	width: 80px;
	height: 80px;
	padding-top: 26px;
	position: absolute;
    right: 50%;
    left: 50%;
    margin-left: -40px;
	top: -5px;
}

.avatar p {
	text-align: center;
	color: #fff;
	font-weight: 700;
	font-size: 30px;
}

.ecard-with-avatar, .ecard {
	border-radius: 20px;
	height: 100px;
}

.ecard-with-avatar .title {
	padding-top: 60px;
	text-align: center;
}

.ecard {
	margin-top: 25px;
}

.ecard .title {
	padding-top: 40px;
	padding-left: 40px;
	text-align: left;
}
.ecard i {
	padding-right: 10px;
	color:#2AD2C9;
}
.ecard-salir{
	margin-top: 50px;
	font-family: 'Raleway', sans-serif;
	color:#999999;
}
.ecard-salir span{
	display: inline-block;
	margin-bottom: 8px;
	margin-left:8px;
}


.goback {
	position: absolute;
	padding: 7px 12px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3);
	cursor: pointer;
}
.goback::before {
	content: "\003c";
  }
.header-int .title {
	margin-top: 10px;
}

.subtitle {
	color: #868686;
	margin-bottom: 5px;
}

.form-select {
	border-radius: 10px;
	width: 200px;
	height: 50px;
}

.product-cont{
	width:100%
}
.product-cont td {
	width: 50%;
	vertical-align: top;
}

.product-img {
	width: 160px;
}

.body-products .ecard {
	padding: 15px;
	height: 200px;
}

.product-title {
	font-weight: 600;
    padding-left: 5px;
    padding-top: 20px;
}
.product-title-presentation{
	font-weight: 600;
    padding-left: 5px;
    padding-top: 5px;
}
.product-stock{
	vertical-align:bottom!important;
	padding-left: 5px;
	padding-bottom: 5px;
}
.btn-solicitar{
	width:100%;
	margin-top:10px;
	margin-bottom:20px;
}

.pointer{
	cursor:pointer;
}


/* --------------------------------------------------------------- */
.body-home-patient {
	background-color: #F5F5F8;
	height: 100vh;
}

.stepbar {
	font-size: 14px;
}

.header-patient-cont td {
	vertical-align: middle;
}

.mt-10 {
	margin-top: 10px;
}

.form-group-lite {
	margin-top: 20px;
}

.form-group-lite p {
	width: 100%;
}

.form-cont {
	margin-top: 25px;
}

.form-cont .form-separator {
	margin-top: 35px;
}
.form-cont button{
	font-family: 'Raleway', sans-serif;
}

.form-text {
	background-color: #fff;
	border-radius: 10px;
	padding: 10px;
}

.disabled {
	background-color: white;
    color: #9193b9;
}

.bordered {
	border: 1px solid #C9C9C9;
}


.mt-18 {
	margin-top: 18px;
}

.hidden {
	display: none;
}

.fw {
	width: 100%;
}

.mt-20 {
	margin-top: 20px;
}

.form-text {
	height: 50px;
}

.form-group-lite-x2 {
	width: 100%;
}

.form-group-lite-x2 td {
	width: 50%;
}

.form-group-lite-x2 td:first-child {
	padding-right: 10px;
}

.datepicker-container{
	width:100%;
}
.datepicker-container input[type=text]{
	margin-top: 4px;
	height: 50px;
	border-radius: 10px;
	font-size: 17px;
}

/**************************************************************************/
.product-request{
	background-color: #F5F5F8;
	font-family: 'Raleway', sans-serif;
	height: 100vh;
}

.product-request .header-int {
	padding-top: 20px;
}

.product-request img{
	width:160px;
}

.product-request .title{
	margin-top:25px;
	margin-left: 15px;
	/* margin-bottom: 25px; */
}
.product-request .subtitle{
	margin-top:5px;
	margin-bottom: 25px;
	color:#212529;
	font-family: 'Raleway', sans-serif;
	font-weight: bold;
}

.product-request .goback{
	margin-top:22px;
}
.product-request .img-frame{
	width: 200px;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 10px;
	margin: auto;
}

.product-request button{
	width: 100%;
	margin-top: 25px;
	height: 45px;
	padding-top: 10px;
}

/***********************************************************************************************/
/*
* ref: https://codepen.io/bronsrobin/pen/AJZmJP
*/
body{
	background-color: #F5F5F8;
}
#steps {
	max-width: 300px;
	margin: 0 auto;
	font-family: 'Raleway', sans-serif;
	text-align: center;
	margin-bottom: 50px;
}

.step {
	width: 40px;
	height: 40px;
	background-color: #717171;
	display: inline-block;
	border: 2px solid;
	border-color: transparent;
	border-radius: 50%;
	color: #ffffff;
	font-weight: 600;
	text-align: center;
	line-height: 35px;
}

.step:first-child {
	line-height: 37px;
}

.step:nth-child(n+2) {
	margin: 0 0 0 100px;
	transform: translate(0, -4px);
}

.step:nth-child(n+2):before {
	width: 80px;
	height: 3px;
	display: block;
	background-color: #B1B1B1;
	transform: translate(-95px, 19px);
	content: "";
	margin-top: -3px;
}

.step:after {
	width: 150px;
	display: block;
	transform: translate(-55px, 3px);
	color: #000;
	content: attr(data-desc);
	font-weight: 600;
	font-size: 13px;
}

.fa-check {
	color: #fff !important;
}

.step:first-child:after {
	transform: translate(-55px, -1px);
}

.step.active {
	border-color: #73b5e8;
	color: #73b5e8;
}

.step.active:before {
	background-color: #B1B1B1;
}

.step.active:after {
	color: #73b5e8;
}

.step.done {
	background-color: #0A9E95;
	color: #fff;
}

.step.done:before {
	background-color: #B1B1B1;
}

.body-home-patient button{
	width: 100%;
    margin-top: 25px;
	height: 45px;
	padding-top: 14px;
}
.patient-logo-container{
	text-align: center;
	display: flex;
  	justify-content: center;
  	align-items: center;
	flex-direction: column;
	min-height: 180px;
}
.patient-logo-container p:last-child{
	margin-top: 40px;
}

.home-button button{
	width: 100%;
    margin-top: 25px;
	height: 45px;
	padding-top: 14px;
	font-family: 'Raleway', sans-serif;
}

.error-card {
	background-color: #fff;
	border-radius: 20px;
	height: 150px;
	text-align: center;
    padding: 40px 5px;
}

.error-card p{
	font-family: 'Raleway', sans-serif;
	font-size: 1.2em;
}
.error-card p:last-child{
	margin-top: 10px;
}

.header-status td {
	vertical-align: middle;
}

.header-status td:nth-child(2) {
	vertical-align: bottom;
	padding-bottom: 40px;
}
.header-status td:nth-child(2) p:nth-child(2){
	margin-top: 20px;
}
.header-status td:nth-child(2) p:nth-child(3){
	margin-top: 5px;
}



/* Timeline Container */
.status-timeline {
	margin: 0 auto;
	padding: 20px;
}

/* Outer Layer with the timeline border */
.status-outer {
	border-left: 2px solid rgb(211, 209, 209);
}

/* Card container */
.status-card {
	position: relative;
	margin: 20px 0 20px 20px;
	padding: 10px;
}

.status-card:first-child{
	padding-top:0;
}
.status-card:last-child{
	height:0;
}
.status-info {
	display: flex;
	flex-direction: column;
	gap: 2px;
}
.status-spinner{
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	height: inherit;
}
.status-logo-container{
	text-align: center;
	display: flex;
  	justify-content: center;
  	align-items: center;
	flex-direction: column;
	min-height: 180px;
}
.status-logo-container p:nth-child(2){
	margin-top: 30px;
	font-size: 0.9rem;
	font-weight: bold;
}
.status-logo-container p:nth-child(3){
	font-size: 0.9rem;
	margin-top: 5px;
	font-weight: lighter;
}



/* Title of the card */
.status-title {
	position: relative;
	font-family: 'Raleway', sans-serif;
	font-weight: bold;
	font-size: 1.2em;
}

.status-subtitle {
	position: relative;
	font-family: 'Raleway', sans-serif;
	font-weight: bold;
	font-size: 0.8em;
}

.status-comment {
	position: relative;
	font-family: 'Raleway', sans-serif;
	font-size: 0.8em;
}

.status-title::before {
	content: attr(data-number);
	position: absolute;
	text-align: center;
	line-height: 20px;
	font-size: smaller;
	font-weight: lighter;
	width: 24px;
	height: 24px;
	left: -43px;
	-moz-border-radius: 12px;
	-webkit-border-radius: 12px;
	border-radius: 12px;
	color:white;
	background-color: #717171;
}

.status-title-ok {
	position: relative;
	font-family: 'Raleway', sans-serif;
	font-weight: bold;
	font-size: 1.2em;
}
.status-title-ok::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f058";
	color:#0A9E95;
	font-size: 24px;
	/* content: attr(data-number); */
	text-align: center;
	line-height: 20px;
	position: absolute;
	/* width: 24px;
	height: 24px; */
	background: #F5F5F8;
	;	
	left: -43px;
	/* -moz-border-radius: 12px;
	-webkit-border-radius: 12px; */
	/* border-radius: 12px; */
	/* background-color: #09cd5e; */
}

.status-title-ok-light {
	position: relative;
	font-family: 'Raleway', sans-serif;
	font-weight: bold;
	font-size: 1.2em;
}
.status-title-ok-light::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f058";
	color:#C9C9C9;
	font-size: 24px;
	/* content: attr(data-number); */
	text-align: center;
	line-height: 20px;
	position: absolute;
	/* width: 24px;
	height: 24px; */
	background: #F5F5F8;
	;	
	left: -43px;
	/* -moz-border-radius: 12px;
	-webkit-border-radius: 12px; */
	/* border-radius: 12px; */
	/* background-color: #09cd5e; */
}

.status-light p, .status-light h3{
	color:#C9C9C9;
}

.button-disabled, .button-disabled:hover{
	background-color: #9fb2d9!important;
	cursor:default;
}

.status-link{
	margin-top: 10px;
	text-align: center;
	
}

.status-link a{
	font-family: 'Raleway', sans-serif;
	color:#0e2d79;
}

.terminos-link a{
	font-family: 'Raleway', sans-serif;
	font-weight: bolder;
}